/***
 *
 *   PUBLIC CLIENT FORM
 *   CAPTURES CLIENT INFORMATION
 *
 **********/

import React, { useCallback, useEffect, useContext } from "react";
import {
  Row,
  useAPI,
  Card,
  Grid,
  ViewContext,
  Icon,
  Link,
  Button,
} from "components/lib";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import clientFormJson from "../website/clientFormJson";
import "survey-core/defaultV2.css";
import Axios from "axios";
StylesManager.applyTheme("defaultV2");

export function ClientView(props) {
  const { id } = useParams();
  // const account = useAPI(`/api/account/public/${id}`);
  const clientAPI = useAPI(`/api/client/${id}`);
  const context = useContext(ViewContext);
  const [client, setClient] = React.useState();
  const [readOnly, setReadOnly] = React.useState("display");
  const practiceInfo = useAPI(`/api/practice`);
  console.log("38", clientFormJson.pages[1].elements[38]);
  clientFormJson.pages[1].elements[47].templateElements[0].choices =
    practiceInfo?.data?.map((l) => {
      return { value: l.id, text: l.name };
    });
  console.log("client", client);
  console.log("practiceInfo", practiceInfo);

  useEffect(() => {
    if (clientAPI?.data && !clientAPI?.loading) {
      setClient(clientAPI);
    }
  }, [clientAPI]);

  const uploadFiles = useCallback(async (sender, options) => {
    // console.log("sender", sender);
    // console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("id", id);
    });

    const uploadResponse = await Axios.post("/api/utility/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("uploadResponse", uploadResponse);

    options.callback(
      "success",
      options.files.map(function (file) {
        console.log("file", file);
        return {
          file: file,
          content: uploadResponse?.data?.data?.files?.find(
            (f) => f.filename === file.name
          )?.url,
        };
      })
    );
  });

  const survey = new Model(clientFormJson);
  survey.focusFirstQuestionAutomatic = true;
  survey.mode = readOnly;

  survey.onDownloadFile.add((sender, options) => {
    console.log("onDownloadFile", sender, options);
    Axios.get("/api/utility/download/" + id, {
      params: { url: options.content },
    }).then((res) => {
      console.log("download", res);
      const fileName = options.name;
      const fileUrl = res?.data?.data?.url;

      options.callback("success", fileUrl);
    });
  });

  survey.data = client?.data?.[0]?.data;

  const surveyComplete = useCallback((sender) => {
    saveSurveyResults("/api/client/" + id, { data: sender.data });
  }, []);

  function getQuestionsFromSurveyJson(surveyJson) {
    const questions = [];
    if (!surveyJson.pages) return questions; // no pages => no questions
  
    for (const page of surveyJson.pages) {
      if (!Array.isArray(page.elements)) continue;
      for (const element of page.elements) {
        // Only handle basic questions here (type: "text", "rating", "comment", etc.)
        // If you have panels, matrixes, etc., you'd handle them differently.
        questions.push({
          name: element.name,     // "userName", "satisfaction", etc.
          title: element.title || element.name // fallback if no title
        });
      }
    }
    return questions;
  }

  function saveCSV(surveyData, surveyJson) {
    if (!surveyData) {
      alert("No survey data to export.");
      return;
    }
    if (!surveyJson || !surveyJson.pages) {
      alert("Survey JSON is invalid or missing pages.");
      return;
    }
  
    // 1. Extract all questions from the Survey JSON
    const questions = getQuestionsFromSurveyJson(surveyJson);
  
    // 2. Filter out any questions the user did NOT answer
    //    i.e., no property in surveyData or value is null/undefined
    const answeredQuestions = questions.filter((q) => {
      return (
        Object.prototype.hasOwnProperty.call(surveyData, q.name) &&
        surveyData[q.name] != null
      );
    });
  
    // If everything was skipped, there's nothing to export
    if (answeredQuestions.length === 0) {
      alert("No answered questions found in survey data.");
      return;
    }
  
    // 3. Build the CSV headers from the question titles
    const headers = answeredQuestions.map((q) => q.title);
  
    // 4. Build a single row using JSON.stringify for each answer
    const row = answeredQuestions.map((q) => {
      // If it's an object/array, it'll be stringified into JSON
      // If it's a string/number, it becomes a quoted string in CSV
      return JSON.stringify(surveyData[q.name]);
    });
  
    // 5. Combine into a CSV string
    const csvString = [headers.join(","), row.join(",")].join("\n");
  
    // 6. Trigger the CSV download
    downloadCSV(csvString, "survey_response.csv");
  }
  function downloadCSV(csvString, filename) {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  survey.onComplete.add(surveyComplete);
  survey.onUploadFiles.add(uploadFiles);
  survey.addNavigationItem({
    id: "csv-export",
    title: "Save as CSV",
    action: () => saveCSV(survey.data, clientFormJson),
  });

  function saveSurveyResults(url, json) {
    Axios.patch(url, json);
  }

  const handleToggle = async (property) => {
    context.modal.show(
      {
        title: `Update ${property.charAt(0).toUpperCase() + property.slice(1)}`,
        form: {
          // review: {
          //   label: property,
          //   type: "select",
          //   options: [
          //     { value: true, label: "Yes" },
          //     { value: false, label: "No" },
          //   ],
          //   default: client?.data?.[0]?.[property],
          // },
        },
        buttonText: "Update",
        text: `Are you sure?`,
        url: `/api/client/${property}/${id}`,
        method: "patch",
      },
      (res) => {
        // console.log("in cb", res);
        const updatedClientProperty = !client?.data?.[0]?.[property];
        const updatedClient = {
          ...client?.data?.[0],
          [property]: updatedClientProperty,
        };
        setClient({ ...client, data: [updatedClient] });
      }
    );
  };

  console.log("client", client);

  return (
    <Grid>
      <Grid cols={6}>
        <Card>
          <Icon
            color="dark"
            image="edit"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl text-sky-500 font-bold underline">
              {/* <Button text="View" textOnly /> */}
              <a
                onClick={() => {
                  setReadOnly(readOnly === "display" ? "edit" : "display");
                }}
              >
                {readOnly === "display" ? "Edit" : "View"}
              </a>
            </p>
            {/* <p className="text-sm text-slate-400">Matches</p> */}
          </div>
        </Card>
        {/* <Card>
          <Icon
            color="dark"
            image="edit"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl text-sky-500 font-bold">
              <Button
                textOnly
                text={readOnly === "display" ? "Edit" : "View"}
                action={() => {
                  setReadOnly(readOnly === "display" ? "edit" : "display");
                }}
              />
            </p>
          </div>
        </Card> */}
        <Card>
          <Icon
            color="dark"
            image="alert-triangle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {client?.data?.[0]?.urgent ? "Yes" : "No"}
            </p>
            <p className="text-sm text-slate-400">
              Urgent (
              <Button
                text="edit"
                textOnly
                className="underline"
                action={handleToggle}
                params="urgent"
              />
              )
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="stop-circle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {client?.data?.[0]?.review ? "Yes" : "No"}
            </p>
            <p className="text-sm text-slate-400">
              Clinical Review (
              <Button
                text="edit"
                textOnly
                className="underline"
                action={handleToggle}
                params="review"
              />
              )
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="stop-circle"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {client?.data?.[0]?.admin_review ? "Yes" : "No"}
            </p>
            <p className="text-sm text-slate-400">
              Admin Review (
              <Button
                text="edit"
                textOnly
                className="underline"
                action={handleToggle}
                params="admin_review"
              />
              )
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="arrow-up"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl font-bold">
              {client?.data?.[0]?.priority ? "Yes" : "No"}
            </p>
            <p className="text-sm text-slate-400">
              Priority (
              <Button
                text="edit"
                textOnly
                className="underline"
                action={handleToggle}
                params="priority"
              />
              )
            </p>
          </div>
        </Card>
        <Card>
          <Icon
            color="dark"
            image="check"
            size={20}
            className="absolute top-1/2 left-4 -translate-y-1/2"
          />
          <div className="relative text-center min-h-max">
            <p className="text-2xl text-sky-500 font-bold">
              {/* <Button text="View" textOnly /> */}
              <Link url={`/match/${id}`}>View</Link>
            </p>
            <p className="text-sm text-slate-400">Matches</p>
          </div>
        </Card>

        {/* <Card>Status</Card>
        <Card>Urgent</Card>
        <Card>Matches</Card> */}
      </Grid>

      <Row>
        <Card>{client?.data.length > 0 && <Survey model={survey} />}</Card>
      </Row>
    </Grid>
  );
}
