import { useAPI, Loader, AccountNav, Button } from "components/lib";
import React from "react";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import { ExportToCsv } from "export-to-csv";
import Axios from "axios";

export function ClientArchive(props) {
  // get all deleted clients
  const clientsAPI = useAPI("/api/client/archive");
  const psych_usersAPI = useAPI("/api/user/psych_profiles");
  const practices = useAPI("/api/practice");
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (clientsAPI?.data && psych_usersAPI?.data) {
      const updatedClients = clientsAPI.data.map((client) => {
        return {
          name: client.name,
          assigned_user:
            psych_usersAPI?.data?.find((u) => u.id === client.user_id)?.name ||
            "Unassigned",
          id: client.id,
          updated_at: client.updated_at,
          age: client.data.Age,
          service: client.data.service,
          mainReason: client.data.mainReason,
          availPanel: client.data.availPanel,
          urgent: client.urgent,
          review: client.review,
          priority: client.priority,
          uploadedDocs: client.data.uploadedDocs,
          whoPays: client.data.whoPays,
          email: client.data.email,
          phone: client.data.phone,
          telehealth: client.data.telehealth,
          practiceNames: (client.data.availPanel || []).map((ap) => {
            return practices?.data?.find((p) => p.id === ap.location)?.name;
          }),
        };
      });

      setClients(updatedClients);
      console.log("updatedClients", updatedClients);
    }
  }, [clientsAPI, psych_usersAPI]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        header: "Assigned To",
        accessorKey: "assigned_user",
      },
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        sortingFn: "datetime",
      },
      {
        accessorFn: (row) => {
          console.log("row", row);
          return Math.trunc(row?.age);
        },
        id: "age",
        header: "Age",
        filterVariant: "range",
        filterFn: "between",
        size: 30,
      },
      {
        accessorKey: "service",
        id: "service",
        header: "Service",
        size: 50,
      },
      {
        accessorFn: (row) => {
          return row?.mainReason?.join(", ");
        },
        id: "presentingIssues",
        header: "Presenting Issues",
      },
      {
        accessorFn: (row) => {
          return row.practiceNames?.join(", ");
        },
        id: "location",
        header: "Locations",
        size: 50,
      },
      {
        accessorFn: (row) => {
          return row?.urgent ? "Yes" : "No";
        },
        header: "Urgent",
      },
      {
        accessorFn: (row) => {
          return row?.review ? "Yes" : "No";
        },
        header: "Review",
      },
      {
        accessorFn: (row) => {
          return row?.priority ? "Yes" : "No";
        },
        header: "Priority",
      },
      {
        accessorFn: (row) => {
          return row?.uploadedDocs ? "Yes" : "No";
        },
        id: "attachments",
        header: "Attachments",
      },
      {
        header: "Funding Source",
        accessorFn: (row) => {
          return row?.whoPays;
        },
        id: "fundingSource",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Phone",
        accessorKey: "phone",
      },
      {
        header: "Telehealth",
        accessorFn: (row) => {
          return row?.telehealth ? "Yes" : "No";
        }
      }
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      "Name",
      "Assigned To",
      "ID",
      "Updated At",
      "Age",
      "Service",
      "Presenting Issues",
      "Locations",
      "Urgent",
      "Review",
      "Priority",
      "Attachments",
      "Funding Source",
      "Email",
      "Phone",
      "Telehealth",
      "Practice Names",
    ],
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    console.log("in ex", clients);
    const toExports = clients;
    csvExporter.generateCsv(toExports);
  };

  // if loading, return loader
  if (clients?.loading) return <Loader />;

  if (clients?.data && clients?.data?.length === 0) {
    return (
      <div>
        <AccountNav />
        <p>No clients found</p>
      </div>
    );
  }

  if (practices?.loading) return <Loader />;

  if (practices?.data && practices?.data?.length === 0) {
    return (
      <div>
        <AccountNav />
        <p>No practices found</p>
      </div>
    );
  }

  const unarchive = async (row) => {
    console.log("unarchive", row.row.original.id);
    const unarchiveAPI = Axios.patch(
      `/api/client/archive/${row.row.original.id}`
    );
    console.log("unarchiveAPI", unarchiveAPI);
    window.location.reload();
  };

  return (
    <div>
      <AccountNav />

      <MaterialReactTable
        columns={columns}
        data={clients}
        enableRowActions
        initialState={{ columnVisibility: { id: false } }}
        renderTopToolbarCustomActions={() => (
          <>
            <div className="ml-auto pt-2">
              <Button
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                action={handleExportData}
                icon="download"
                text="Download"
                textOnly
                outline
                alignLeft
              />
            </div>
          </>
        )}
        positionActionsColumn="last"
        renderRowActions={(row, index) => [
          <>
            <Button
              small
              color="blue"
              action={unarchive}
              params={row}
              text="Unarchive"
            ></Button>
          </>,
        ]}
      />
    </div>
  );
}
